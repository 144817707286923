@font-face {
font-family: 'MomentsDisplayBG';
src: url(/_next/static/media/5b277404671d584f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: 'MomentsDisplayBG';
src: url(/_next/static/media/92169a77ae7021e6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'MomentsDisplayBG';
src: url(/_next/static/media/5d14a443359326f1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: 'MomentsDisplayBG Fallback';src: local("Arial");ascent-override: 115.99%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 82.07%
}.__className_10e9d1 {font-family: 'MomentsDisplayBG', 'MomentsDisplayBG Fallback'
}.__variable_10e9d1 {--font-oetker-moments-display: 'MomentsDisplayBG', 'MomentsDisplayBG Fallback'
}

@font-face {
font-family: 'MomentsTextBG';
src: url(/_next/static/media/17bfe8cf53a3d4b9-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'MomentsTextBG Fallback';src: local("Arial");ascent-override: 95.64%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 99.44%
}.__className_16b3bf {font-family: 'MomentsTextBG', 'MomentsTextBG Fallback'
}.__variable_16b3bf {--font-oetker-moments-text: 'MomentsTextBG', 'MomentsTextBG Fallback'
}

@font-face {
font-family: 'MomentsDisplayTR';
src: url(/_next/static/media/035109d8c99ef9d7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: 'MomentsDisplayTR';
src: url(/_next/static/media/637bb93d823180d3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'MomentsDisplayTR';
src: url(/_next/static/media/682e40a3a4e6cbbe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: 'MomentsDisplayTR Fallback';src: local("Arial");ascent-override: 115.99%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 82.07%
}.__className_41d2b8 {font-family: 'MomentsDisplayTR', 'MomentsDisplayTR Fallback'
}.__variable_41d2b8 {--font-oetker-moments-display: 'MomentsDisplayTR', 'MomentsDisplayTR Fallback'
}

@font-face {
font-family: 'MomentsTextTR';
src: url(/_next/static/media/5d4953858d636d04-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'MomentsTextTR Fallback';src: local("Arial");ascent-override: 95.64%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 99.44%
}.__className_30ab5d {font-family: 'MomentsTextTR', 'MomentsTextTR Fallback'
}.__variable_30ab5d {--font-oetker-moments-text: 'MomentsTextTR', 'MomentsTextTR Fallback'
}

@font-face {
font-family: 'Ceramika';
src: url(/_next/static/media/a76bcb7d27569ff5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: 'Ceramika Fallback';src: local("Arial");ascent-override: 105.78%;descent-override: 22.59%;line-gap-override: 0.00%;size-adjust: 97.37%
}.__className_393df4 {font-family: 'Ceramika', 'Ceramika Fallback';font-weight: 400
}.__variable_393df4 {--font-ceramika: 'Ceramika', 'Ceramika Fallback'
}

@font-face {
font-family: 'FuturaPT';
src: url(/_next/static/media/ce8875d226ed4827-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: 'FuturaPT Fallback';src: local("Arial");ascent-override: 108.56%;descent-override: 33.17%;line-gap-override: 0.00%;size-adjust: 90.45%
}.__className_f8b59c {font-family: 'FuturaPT', 'FuturaPT Fallback';font-weight: 500
}.__variable_f8b59c {--font-futura-pt: 'FuturaPT', 'FuturaPT Fallback'
}

@font-face {
font-family: 'AugustScript';
src: url(/_next/static/media/a2e99cde1fb15c40-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'AugustScript Fallback';src: local("Arial");ascent-override: 118.23%;descent-override: 48.09%;line-gap-override: 0.00%;size-adjust: 82.97%
}.__className_d2e1ed {font-family: 'AugustScript', 'AugustScript Fallback'
}.__variable_d2e1ed {--font-oetker-august-script: 'AugustScript', 'AugustScript Fallback'
}

@font-face {
font-family: 'MomentsDisplay';
src: url(/_next/static/media/6c0a6a60376c4ea4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: 'MomentsDisplay';
src: url(/_next/static/media/6ef8a300e8fd6239-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'MomentsDisplay';
src: url(/_next/static/media/10facfa8edca2ef9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: 'MomentsDisplay Fallback';src: local("Arial");ascent-override: 115.99%;descent-override: 29.97%;line-gap-override: 0.00%;size-adjust: 82.07%
}.__className_a73c4e {font-family: 'MomentsDisplay', 'MomentsDisplay Fallback'
}.__variable_a73c4e {--font-oetker-moments-display: 'MomentsDisplay', 'MomentsDisplay Fallback'
}

@font-face {
font-family: 'MomentsText';
src: url(/_next/static/media/b3454eed89952848-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'MomentsText Fallback';src: local("Arial");ascent-override: 95.64%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 99.44%
}.__className_0a3ea5 {font-family: 'MomentsText', 'MomentsText Fallback'
}.__variable_0a3ea5 {--font-oetker-moments-text: 'MomentsText', 'MomentsText Fallback'
}

